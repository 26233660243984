<template>
  <v-row class="select-device">
    <v-col cols="3">
      <v-select @input="saveSelectionTimeSpan" background-color="grey" filled label="Time Span" :value="getSelectedTimeSpan" :items="timeSpans"></v-select>
    </v-col>
    <v-col cols="9">
      <v-select @input="saveSelectionDevice" background-color="grey" filled label="Device" :value="getSelectedDevices" :items="getDevices"></v-select>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SelectDevice",
  data() {
    return {
      timeSpans: ['1h', '2h', '8h', '12h', '24h', '1d', '2d', '7d']
    }
  },
  computed: {
    ...mapGetters(['getDevices', 'getSelectedDevices', 'getSelectedTimeSpan'])
  },
  methods: {
    ...mapActions(['setSelectedDevice', 'setSelectedTimeSpan']),
    saveSelectionDevice (selection) {
      this.setSelectedDevice(selection)
    },
    saveSelectionTimeSpan (selection) {
      this.setSelectedTimeSpan(selection)
    },
  }
}
</script>

<style scoped>
  .select-device {
    margin-top: 30px;
  }
</style>

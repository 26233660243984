import Vuex from 'vuex'
import Vue from 'vue'
import settings from "@/store/modules/settings";
import data from "@/store/modules/data";

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    settings,
    data
  },
})

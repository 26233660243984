<template>
  <v-container>
    <DataView v-if="getSelectedDevices"></DataView>
    <v-row justify="center" class="text-center">
      <v-col cols="12" v-if="!getSelectedDevices">
        <v-img
          class="my-3"
          :src="require('../assets/logo.png')"
          contain
          height="500"
        />
      </v-col>
      <template v-if="getDevices.length === 0">
        <v-col lg="6" md="7" sm="9" xs="12">
          <v-text-field type="password"  @click:append="pasteAccessKey" class="text-field" :append-icon="'mdi-content-paste'" v-model="accessKey" label="Access Key" outlined height="60"></v-text-field>
        </v-col>
        <v-col lg="3" md="3" sm="3" xs="12">
          <v-btn :color="this.accessKey ? 'success' : 'grey'" width="100%" height="60" @click="saveAccessKey">Save</v-btn>
        </v-col>
      </template>
      <template v-if="getDevices.length > 0 && !getSelectedDevices">
        <SelectDevice></SelectDevice>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectDevice from "@/components/SelectDevice";
import DataView from "@/components/DataView";
  export default {
    name: 'HelloWorld',
    components: {DataView, SelectDevice},
    created() {
      this.accessKey = this.getAccessKey
    },
    data: () => ({
      accessKey: ''
    }),
    computed: {
      ...mapGetters(['getAccessKey', 'getDevices', 'getSelectedDevices', 'getSelectedTimeSpan'])
    },
    methods: {
      ...mapActions(['setAccessKey']),
      saveAccessKey() {
        this.setAccessKey(this.accessKey)
      },
      async pasteAccessKey() {
        const pasted = await navigator.clipboard.readText()
        this.accessKey = pasted
      }
    }
  }
</script>

<style lang="scss">
  .text-field {
    min-width: 300px;
  }
</style>

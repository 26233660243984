// import axios from "axios";

import axios from "axios";

const state = {
    sensorData: [],
    loadingData: false
}

const getters = {
    getSensorData: state => {
        if (window.localStorage['data']){
            state.sensorData = JSON.parse(window.localStorage.getItem('data'))
            return state.sensorData
        }
        return state.sensorData
    },
    getStatusLoadingData: state => {
        return state.loadingData
    }
}

const actions = {

    setSensorData({ state }, data) {
        window.localStorage.setItem('data', data)
        state.sensorData = data
    },

    async loadSensorData({ state, dispatch }) {
        const base64js = require('base64-js')
        state.loadingData = true
        const selectedDevice =  window.localStorage.getItem('selectedDevice')
        const selectedTimespan = window.localStorage.getItem('selectedTimespan')
        const accessKey = window.localStorage.getItem('accessKey')
        axios.get(
            `https://thingproxy.freeboard.io/fetch/https://plant_io_moisturesensor.data.thethingsnetwork.org/api/v2/query/${selectedDevice}?last=${selectedTimespan}`,
            {headers: { Authorization: 'key ' + accessKey}}).then(response => {
            console.log(response.data)
            state.loadingData = false
            let preparedData;
            if (response.data.length !== 0) {
                preparedData = response.data.map(item => {
                    const value = base64js.toByteArray(item.raw)
                    const convertedValue = value[0] * 1000 + value[1] * 100 + value[2] * 10 + value[3] * 1
                    return {
                        date: item.time,
                        value: convertedValue,
                        deviceId: item.device_id
                    }
                })
            } else {
                preparedData = []
            }
            dispatch('setSensorData', JSON.stringify(preparedData))
        }).catch(error => {
            state.loadingData = false
            console.log(error)
        })
    },
}

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations
}

<template>
  <div>
    <v-card
        class="chart-card"
        max-width="100%"
    >

      <v-card class="chart-card">
<!--        <v-progress-linear v-if="getStatusLoadingData" color="green" indeterminate></v-progress-linear>-->
        <LineChart :key="updateKey" :data="preparedData" :data-label="dataLabel" :labels="preparedLabels" class="chart"></LineChart>
      </v-card>

      <v-card-text class="pt-0">
        <v-divider class="my-2"></v-divider>
        <v-icon
            class="mr-2"
            small
        >
          mdi-clock
        </v-icon>
        <span class="caption grey--text font-weight-light">last measurement {{ preparedLabels[preparedLabels.length - 1] }}</span>
      </v-card-text>
    </v-card>
    <PlantMoistureTable class="table" :data="getSensorData"></PlantMoistureTable>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LineChart from "@/components/LineChart";
import moment from 'moment'
import PlantMoistureTable from "@/components/SensorDataTable";

export default {
  name: "DataView",
  components: {PlantMoistureTable, LineChart},
  data(){
    return {
      dataLabel: 'Moisture in %',
      updateKey: 100
    }
  },
  created() {
    this.getData()

  },
  computed: {
    ...mapGetters(['getSensorData', 'getSelectedDevices', 'getSelectedTimeSpan', 'getStatusLoadingData']),
    preparedData() {
      return this.getSensorData.map(item => {
        return item.value
      })
    },
    preparedLabels() {
      return this.getSensorData.map(item => {
        return  moment(item.date).fromNow()
      })
    }
  },
  watch: {
    getSensorData() {
      this.updateKey++
    },
    getSelectedDevices() {
      this.getData()
    },
    getSelectedTimeSpan() {
      this.getData()
    }
  },
  methods: {
    ...mapActions(['loadSensorData']),
    getData() {
      this.loadSensorData()
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-card {
  margin-top: 30px;
  height: 800px;
}
.chart {
  height: 800px;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.table {
  margin-top: 100px;
  margin-bottom: 100px;
  height: fit-content;
}
</style>

import axios from "axios";

const state = {
    accessKey: '',
    devices: [],
    loadingDevices: false,
    selectedDevice: '',
    selectedTimespan: '1d'
}

const getters = {
    getAccessKey: state => {
        if (window.localStorage['accessKey']){
            state.accessKey = window.localStorage.getItem('accessKey')
            return state.accessKey
        }
        return state.accessKey
    },
    getDevices: state => {
        if (window.localStorage['devices']){
            state.devices = window.localStorage.getItem('devices').split(',')
            return state.devices
        }
        return state.devices
    },
    getSelectedDevices: state => {
        if (window.localStorage['selectedDevice']){
            state.selectedDevice = window.localStorage.getItem('selectedDevice')
            return state.selectedDevice
        }
        return state.selectedDevice
    },
    getSelectedTimeSpan: state => {
        if (window.localStorage['selectedTimespan']){
            state.selectedTimespan = window.localStorage.getItem('selectedTimespan')
            return state.selectedTimespan
        }
        return state.selectedTimespan
    }
}

const actions = {
    setAccessKey({ state, dispatch }, key) {
        window.localStorage.setItem('accessKey', key)
        dispatch('loadDevices')
        state.accessKey = key
    },
    setDevices({ state }, devices) {
        window.localStorage.setItem('devices', devices)
        state.devices = devices
    },
    setSelectedDevice({ state }, device) {
        window.localStorage.setItem('selectedDevice', device)
        state.selectedDevice = device
    },
    setSelectedTimeSpan({ state }, timeSpan) {
        window.localStorage.setItem('selectedTimespan', timeSpan)
        state.selectedTimespan = timeSpan
    },
    async loadDevices({ state, dispatch }) {
        state.loadingDevices = true
        axios.get(
            'https://thingproxy.freeboard.io/fetch/https://plant_io_moisturesensor.data.thethingsnetwork.org/api/v2/devices',
            {headers: { Authorization: 'key ' + state.accessKey}}).then(response => {
            console.log(response.data)
            dispatch('setDevices', response.data)
            state.loadingDevices = false
        }).catch(error => {
            state.loadingDevices = false
            console.log(error)
        })
    },
    // loadAvailableDevices ({ state }) {
    //
    // }
}

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations
}

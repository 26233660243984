<template>
    <v-card
        class="chart-card"
        max-width="100%"
    >
      <template>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Date
              </th>
              <th class="text-left">
                Device ID
              </th>
              <th class="text-left">
                Value
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in data.reverse()"
                :key="item.date"
            >
              <td>{{ getDate(item.date) }}</td>
              <td>{{ item.deviceId }}</td>
              <td>{{ item.value }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>
</template>

<script>
import moment from 'moment'
export default {
  name: "PlantMoistureTable",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('DD.MM.YYYY - HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-card {
    margin-top: 30px;
    height: 800px;
  }
  .chart {
    height: 800px;
  }
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

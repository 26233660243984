<script>
import { Line} from 'vue-chartjs'
export default {
  name: "LineChart",
  extends: Line,
  props: {
    data: {
      type: Array,
      required: true
    },
    dataLabel: {
      type: String,
      required: false
    },
    labels: {
      type: Array,
      required: true
    }
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          label: this.dataLabel,
          backgroundColor: '#69ecec',
          data: this.data
        }
      ]
    }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>

<style scoped>

</style>

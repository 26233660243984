<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-3"
      height="90"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo_text.png')"
          transition="scale-transition"
          width="150"
        />
      </div>
      <v-spacer></v-spacer>
      <SelectDevice v-if="$store.getters.getSelectedDevices"></SelectDevice>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/MainView';
import SelectDevice from "@/components/SelectDevice";

export default {
  name: 'App',
  components: {
    SelectDevice,
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">

</style>
